<template>
  <div>
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template v-if="hasModuleAccess">
        <Card class="px-3 py-3 flex flex-col gap-5">
          <div
            class="text-introduction gap-2 text-base flex align-justify flex-col bg-ghostWhite px-3 py-3 rounded-md"
          >
            <p>
              Your pay grades define specific pay ranges to help you control pay
              expenses and enusre pay equity among employees and employee
              groups, keeping into account positions, responsibilities,
              seniority, experience, etc. This setting is intended for general
              use to help you set up compensation grades and salary ranges.
            </p>
            <p>
              Before you setup, decide your organisation's compensation
              philosophy and stay consistent in its application. If you decide
              to reference market rates of pay, you can also choose to lead, lag
              or match the market rate. The policy percentage will help you
              either lead, lag or match the market rate.
            </p>
          </div>
          <div class="flex justify-end items-center">
            <Button
              label="Add New"
              class="text-white button-class-pimary btn-bg"
              :disabled="referenceMarketRate === null || isLoading"
              @onClick="onPayGradeActions(referenceMarketRate, 'create')"
            />
            <CreatePayGrade ref="create_paygrade" @success="onGetQuery()" />
          </div>
          <template v-slot:footer>
            <CardFooter
              reloadcard
              @reload="onGetQuery"
              @sortType="onGetQuery({ sort: $event })"
              @searchResult="onGetQuery({ search: $event })"
            />
          </template>
        </Card>
        <template>
          <div class="flex justify-start items-end gap-10 mt-5">
            <span class="font-semibold text-base text-darkPurple">
              Reference Market Rate
            </span>
            <div class="flex justify-start items-center gap-5">
              <label class="flex justify-start items-center gap-2">
                <input
                  type="radio"
                  :value="true"
                  name="referenceMarketRate"
                  v-model="isRefMarketRate"
                  @click="isOpenMarketState = true"
                  :disabled="isSetMarketRate"
                />
                <span class="text-jet text-base font-normal leading-5">
                  Yes
                </span>
              </label>
              <label class="flex justify-start items-center gap-2">
                <input
                  type="radio"
                  :value="false"
                  name="referenceMarketRate"
                  v-model="isRefMarketRate"
                  @click="isOpenMarketState = true"
                  :disabled="isSetMarketRate"
                />
                <span class="text-jet text-base font-normal leading-5">
                  No
                </span>
              </label>
            </div>
          </div>
        </template>
        <template v-if="activeTableItems.length">
          <Table
            :headers="headers"
            :items="activeTableItems"
            aria-label="range speed table"
            class="w-full pt-4 mb-4 overflow-x-auto"
            :has-number="true"
            :loading="isFetching"
            :pagination-list="metaData"
            @page="onGetQuery({ page: $event })"
            @itemsPerPage="onGetQuery({ perPage: $event })"
            page-sync
          >
            <template v-slot:item="{ item }">
              <div v-if="item.locations" class="w-28">
                <div v-if="Array.isArray(item.data.locations)">
                  <div class="flex flex-col justiy-start gap-2">
                    <div
                      class="flex flex-row justify-between items-center gap-1"
                    >
                      <p class="text-sm text-darkPurple">
                        {{ item.data.locations[0].name }}
                      </p>
                      <template v-if="item.data.locations.length - 1 > 0">
                        <p class="text-xs text-flame font-bold">
                          +{{ item.data.locations.length - 1 }}
                        </p>
                      </template>
                    </div>
                    <template v-if="item.data.locations.length > 1">
                      <div
                        @click="
                          (activeLocations = item.data.locations),
                            (isOpenLocations = true)
                        "
                      >
                        <p
                          class="text-xs text-blueCrayola font-semibold cursor-pointer"
                        >
                          View Trail
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
                <span
                  v-else
                  class="font-normal text-sm leading-5 text-darkPurple"
                >
                  {{ item.data.locations }}
                </span>
              </div>
              <div v-if="item.functions">
                <div v-if="Array.isArray(item.data.functions)">
                  <div
                    v-for="item in item.data.functions"
                    :key="item.id"
                    class="flex justify-start gap-2"
                  >
                    <span class="font-normal text-sm leading-5 text-darkPurple">
                      {{ item.name }}
                    </span>
                  </div>
                </div>
                <span
                  v-else
                  class="font-normal text-sm leading-5 text-darkPurple"
                >
                  {{ item.data.functions }}
                </span>
              </div>
              <div v-if="item.payGradeId">
                <span class="font-normal text-sm leading-5 text-darkPurple">
                  {{ item.data.payGradeId }}
                </span>
              </div>
              <div v-if="item.level">
                <span
                  class="font-normal text-sm leading-5 text-darkPurple whitespace-nowrap"
                >
                  {{ item.data.level.name }}
                </span>
              </div>
              <div v-if="item.currentMarketRate">
                <div
                  class="font-normal text-sm leading-5 text-darkPurple whitespace-nowrap"
                >
                  <span v-if="item.data.currentMarketRate">
                    {{ convertToCurrency(item.data.currentMarketRate) }}
                  </span>
                </div>
              </div>
              <div v-if="item.rangeMidPoint">
                <span
                  class="font-normal text-sm leading-5 text-darkPurple whitespace-nowrap"
                >
                  <span v-if="item.data.rangeMidPoint">
                    {{ convertToCurrency(item.data.rangeMidPoint) }}
                  </span>
                </span>
              </div>
              <div v-if="item.rangeMinimum">
                <span
                  class="font-normal text-sm leading-5 text-darkPurple whitespace-nowrap"
                >
                  <span v-if="item.data.rangeMinimum">
                    {{ convertToCurrency(item.data.rangeMinimum) }}
                  </span>
                </span>
              </div>
              <div v-if="item.rangeMaximum">
                <span
                  class="font-normal text-sm leading-5 text-darkPurple whitespace-nowrap"
                >
                  <span v-if="item.data.rangeMaximum">
                    {{ convertToCurrency(item.data.rangeMaximum) }}
                  </span>
                </span>
              </div>
              <div v-else-if="item.id">
                <Menu left style-name="margin-right:20px" class="my-2">
                  <template v-slot:title>
                    <Icon icon-name="more_icon" size="xs" />
                  </template>
                  <div
                    class="w-28 h-18 px-2 py-2 flex flex-col justify-start items-start gap-2"
                  >
                    <div
                      @click="$refs.view_paygrade.onToggle(item.data.id)"
                      class="w-full"
                    >
                      <div
                        class="w-full px-2 py-1 rounded-md flex justify-start items-center gap-2 cursor-pointer hover:bg-cultured"
                      >
                        <Icon
                          icon-name="icon-eye"
                          class-name="text-blueCrayola"
                          size="xs"
                        />
                        <span
                          class="text-sm font-normal leading-5 text-darkPurple"
                          >View</span
                        >
                      </div>
                    </div>
                    <div
                      @click="onPayGradeActions(item.data, 'update')"
                      class="w-full"
                    >
                      <div
                        class="w-full px-2 py-1 rounded-md flex justify-start items-center gap-2 cursor-pointer hover:bg-cultured"
                      >
                        <Icon
                          icon-name="edit"
                          class-name="text-blueCrayola"
                          size="xs"
                        />
                        <span
                          class="text-sm font-normal leading-5 text-darkPurple"
                          >Edit</span
                        >
                      </div>
                    </div>
                    <div
                      @click="onPayGradeActions(item.data.id, 'delete')"
                      class="w-full"
                    >
                      <div
                        class="w-full px-2 py-1 rounded-md flex justify-start items-center gap-2 cursor-pointer hover:bg-cultured"
                      >
                        <Icon
                          icon-name="delete"
                          class-name="text-flame bg-white"
                          size="xs"
                        />
                        <span
                          class="text-sm font-normal leading-5 text-darkPurple"
                          >Delete</span
                        >
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
            </template>
          </Table>
        </template>
        <template v-else>
          <template v-if="referenceMarketRate !== null">
            <div
              class="flex flex-col justify-center items-center gap-5 px-10 py-20"
            >
              <Icon
                icon-name="paygrade_Illustration"
                size=""
                class-name="w-56 h-56"
              />
              <p class="font-normal text-base leading-5 text-jet text-center">
                An Overview of Pay Grades can be found here. This is empty now,
                but you can start adding pay grades to populate this area.
              </p>
              <div
                class="hover:bg-ghostWhite px-5 py-2 rounded-md cursor-pointer"
              >
                <div
                  class="flex justify-start items-center gap-2"
                  @click="onPayGradeActions(referenceMarketRate, 'create')"
                >
                  <Icon icon-name="icon-plus" size="xs" />
                  <span class="font-semibold text-base text-flame leading-5">
                    Add New
                  </span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="flex flex-col justify-center items-center gap-5 px-10 py-20"
            >
              <Icon
                icon-name="illustration_approval"
                size=""
                class-name="w-56 h-56 text-white"
              />
              <p class="font-normal text-base leading-5 text-jet text-center">
                Please select a checkbox above to set up your market rate.
                Market rate settings can only be changed once every financial
                year.
              </p>
            </div>
          </template>
        </template>
        <template>
          <ViewPayGrade ref="view_paygrade" />
          <UpdatePayGrade ref="update_paygrade" @success="onGetQuery()" />
        </template>
        <template>
          <Modal v-if="isOpenMarketState">
            <Card class="p-2 px-3 flex flex-col gap-3 max-w-md">
              <template>
                <div class="border-b border-romanSilver">
                  <p class="text-jet font-bold text-base">
                    Change Market Rate Preferences
                  </p>
                </div>
                <div class="flex flex-col justify-start gap-2">
                  <p>
                    You are about to change your market rate preferences for
                    this financial year.
                  </p>
                  <Alert
                    message="This can only be set once within a financial cycle"
                    variant="primary"
                    :time="2"
                    class="alert-style"
                  />
                  <p>Do you wish to continue?</p>
                  <div class="flex justify-end items-center gap-2">
                    <Button
                      class="button-class-pimary btn-bg"
                      label="Continue"
                      @onClick="onSetMarketState('continue')"
                    />
                    <Button
                      label="Cancel"
                      class="button-class-secondary btn-border"
                      @onClick="onSetMarketState('cancelled')"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </Modal>
        </template>
        <template>
          <Modal v-if="isOpenDelete">
            <Card class="p-5 flex flex-col max-w-md">
              <div class>
                <p class="font-bold ml-4 my-5 text-center">
                  Are you sure you want to delete this paygrade?
                </p>
                <div class="flex justify-end items-center gap-2">
                  <Button
                    class="button-class-pimary btn-bg"
                    label="Delete"
                    @onClick="onDelete(delPayGradeId)"
                  />
                  <Button
                    label="Cancel"
                    class="button-class-secondary btn-border"
                    @onClick="isOpenDelete = false"
                  />
                </div>
              </div>
            </Card>
          </Modal>
        </template>
        <template>
          <RightSideBar
            v-if="isOpenLocations"
            @close="isOpenLocations = false"
            button-class="bg-dynamicBackBtn text-white"
            :close-button="true"
          >
            <template v-slot:title>
              <div class="flex justify-start items-center">
                <p
                  class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2"
                >
                  View All Locations
                </p>
              </div>
            </template>
            <template v-slot:default>
              <div class="flex flex-col justify-start gap-2">
                <Card
                  v-for="location in activeLocations"
                  :key="location.id"
                  class="w-full"
                >
                  <div
                    class="flex flex-row items-center justify-start gap-2 mx-5 my-3"
                  >
                    <Icon
                      icon-name="icon-grid"
                      class="text-romanSilver"
                      size="xxs"
                    />
                    <h6 class="font-semibold text-sm text-jet capitalize">
                      {{ location.name }}
                    </h6>
                  </div>
                </Card>
              </div>
            </template>
          </RightSideBar>
        </template>
      </template>
      <ErrorComponent v-else />
    </template>
  </div>
</template>

<script>
import * as _ from "lodash";
import { mapState, mapActions } from "vuex";
import Loader from "@scelloo/cloudenly-ui/src/components/loader";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Button from "@scelloo/cloudenly-ui/src/components/button";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import Icon from "@/components/Icon";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  name: "PayGradeSettings",
  components: {
    Alert,
    Loader,
    Card,
    Table,
    Button,
    Icon,
    Menu,
    Modal,
    CardFooter,
    RightSideBar,
    ErrorComponent,
    CreatePayGrade: () => import("./Modals/CreatePayGrade"),
    UpdatePayGrade: () => import("./Modals/UpdatePayGrade"),
    ViewPayGrade: () => import("./Modals/ViewPayGrade"),
  },
  data() {
    return {
      isLoading: true,
      isFetching: true,
      hasModuleAccess: false,
      isOpenLocations: false,
      isOpenMarketState: false,
      metaData: {},
      isOpenDelete: false,
      activeLocations: [],
      paygradeId: "",
      payGradeName: "",
      delPayGradeId: "",
      queryParams: {},
      isRefMarketRate: null,
      disabledMarketRate: false,
      withMarketRate: [],
      withoutMarketRate: [],
      activeTableItems: [],
      headers: [],
      paginationList: {
        page: 1,
        lastPage: 1,
        total: 1,
        from: 1,
        to: 1,
      },
    };
  },
  computed: {
    ...mapState({
      referenceMarketRate: (state) => state.compensation.referenceMarketRate,
      toasted: (state) => state.compensation.toasted,
      isSetMarketRate: (state) => state.compensation.isSetMarketRate,
    }),
  },
  methods: {
    ...mapActions({
      setMarketSettings: "compensation/setMarketRateSettings",
      setIsLoading: "compensation/setIsLoading",
    }),
    async onPayGradeActions(payload, setAction) {
      try {
        if (setAction === "create") {
          await this.$handlePrivilege("compensation", "addNewPayGrade");
          this.$refs.create_paygrade.onToggle(payload);
        } else if (setAction === "update") {
          await this.$handlePrivilege("compensation", "editPayGrade");
          this.$refs.update_paygrade.onToggle(payload);
        } else if (setAction === "delete") {
          await this.$handlePrivilege("compensation", "deletePayGrade");
          this.isOpenDelete = true;
          this.delPayGradeId = payload;
        }
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    onSetMarketState(setAction) {
      if (setAction === "continue") {
        this.setMarketSettings(this.isRefMarketRate);
        this.isOpenMarketState = false;
      } else if (setAction === "cancelled") {
        this.isRefMarketRate = this.referenceMarketRate;
        this.isOpenMarketState = false;
      }
    },
    onGetQuery(queryParams) {
      if (queryParams !== undefined) {
        this.queryParams = { ...this.queryParams, ...queryParams };
        this.getOrgCompensationPayGrades(this.queryParams);
      } else {
        this.queryParams = {};
        this.getOrgCompensationPayGrades();
      }
    },
    async getOrgCompensationPayGrades(queryParams) {
      this.isFetching = true;
      this.withMarketRate = [];
      this.withoutMarketRate = [];
      const $fn = (fn) => fn || "-";
      await this.$_getOrgCompensationPayGrades(queryParams).then(({ data }) => {
        this.metaData = data.meta;
        const groupedBy = _.groupBy(data.data, "paygrade.referenceMarketRate");
        if (!_.isEmpty(groupedBy.false)) {
          groupedBy.false?.forEach(async (group) => {
            this.withoutMarketRate.push({
              ...group,
              id: $fn(group.paygrade.id),
              payGradeId: $fn(group.paygrade.payGradeId),
              payGradeName: $fn(group.paygrade.payGradeName),
              currentMarketRate: $fn(group.paygrade.currentMarketRate),
              rangeMidPoint: $fn(group.paygrade.rangeMidPoint),
              rangeMinimum: $fn(group.paygrade.rangeMinimum),
              rangeMaximum: $fn(group.paygrade.rangeMaximum),
            });
          });
        }
        if (!_.isEmpty(groupedBy.true)) {
          groupedBy.true?.forEach(async (group) => {
            this.withMarketRate.push({
              ...group,
              id: $fn(group.paygrade.id),
              payGradeId: $fn(group.paygrade.payGradeId),
              payGradeName: $fn(group.paygrade.payGradeName),
              currentMarketRate: $fn(group.paygrade.currentMarketRate),
              rangeMidPoint: $fn(group.paygrade.rangeMidPoint),
              rangeMinimum: $fn(group.paygrade.rangeMinimum),
              rangeMaximum: $fn(group.paygrade.rangeMaximum),
            });
          });
        }
        if (!this.referenceMarketRate) {
          this.activeTableItems = this.withoutMarketRate;
        } else this.activeTableItems = this.withMarketRate;
        this.isFetching = false;
      });
    },
    onDelete(paygradeId) {
      this.isFetching = true;
      this.isOpenDelete = false;

      this.$_deletePayGradeById(paygradeId)
        .then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 });
          this.isFetching = false;
          this.onGetQuery();
        })
        .catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
          this.isFetching = false;
          this.onGetQuery();
        });
    },
    changeActiveTable(value) {
      this.isFetching = true;
      if (value) {
        this.activeTableItems = this.withMarketRate;
        this.headers = [
          { title: "location", value: "locations" },
          { title: "job level", value: "level" },
          { title: "pay grade", value: "payGradeId" },
          { title: "current market rate", value: "currentMarketRate" },
          { title: "annual range midpoint", value: "rangeMidPoint" },
          { title: "annual range minimum", value: "rangeMinimum" },
          { title: "annual range maximum", value: "rangeMaximum" },
          { title: "", value: "id", image: true },
        ];
      } else {
        this.activeTableItems = this.withoutMarketRate;
        this.headers = [
          { title: "location", value: "locations" },
          { title: "function", value: "functions" },
          { title: "job level", value: "level" },
          { title: "pay grade", value: "payGradeId" },
          { title: "annual range midpoint", value: "rangeMidPoint" },
          { title: "annual range minimum", value: "rangeMinimum" },
          { title: "annual range maximum", value: "rangeMaximum" },
          { title: "", value: "id", image: true },
        ];
      }
      this.isFetching = false;
    },
  },
  async created() {
    this.isLoading = true;

    try {
      await this.$handlePrivilege("compensation", "accessPayGradeSettings");
      this.hasModuleAccess = true;
      await this.setMarketSettings(null);
      this.isRefMarketRate = this.referenceMarketRate;
      this.changeActiveTable(this.referenceMarketRate);
      await this.getOrgCompensationPayGrades();
    } catch (error) {
      this.hasModuleAccess = false;
    }

    this.isLoading = false;
  },
  watch: {
    toasted(toast) {
      if (toast.type === "success") {
        this.$toasted.success(toast.message, { duration: 3000 });
      } else if (toast.type === "error") {
        this.$toasted.error(toast.message, { duration: 3000 });
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  appearance: none;
  background-color: #fff;
  width: 17px;
  height: 17px;
  border: 2px solid #ccc;
  border-radius: 2px;
  display: inline-grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #fff;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
input[type="radio"]:checked {
  background-color: #f15a29;
  border: 2px solid #f15a29;
}
.alert-style {
  position: relative;
  right: 0;
  top: -5px;
  box-shadow: none;
  background: #ffffff;
  color: #e99323;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid rgb(254 202 202);
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
  background-color: var(--dynamic-Background) !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.text-introduction {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: justify;
  letter-spacing: 0.015em;
  color: #333333;
}
.text-10 {
  font-size: 10px !important;
}

.btn-bg {
  background-color: var(--dynamic-Background) !important;
}

.btn-border {
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
